import Vue from "vue";

export default {
};

export const utils = {
  install(Vue) {
    Vue.prototype.$utils = {
      separateThousands: function (text) {
        var result = "";
        var minus = false;
        if ((text + '').charAt(0) == '-') {
          minus = true;
        }
        var newText = (text + '').replace(/,/g, "").replace(/-/g, "");
        for (var i = 1; i <= newText.length; i++) {
          result = newText.charAt(newText.length - i) + result;
          if (i % 3 == 0 && i != newText.length) {
            result = "," + result;
          }
        }
        if (minus) {
          result = '-' + result;
        }
        return result;
      },
      getCardNumber: function (text) {
        var result = "";
        var newText = (text + '').replace(/-/g, "");
        for (var i = 1; i <= newText.length; i++) {
          result = newText.charAt(newText.length - i) + result;
          if (i % 4 == 0 && i != newText.length) {
            result = "-" + result;
          }
        }
        return result;
      },
      toNumber: function (text) {
        let number = text * 1;
        return number;
      },
      toText: function (number) {
        let text = number + '';
        return text;
      },
      lpad: function (text, padString, length) { // 숫자 앞에 0 붙이기
        let str = '' + text;
        while (str.length < length)
          str = padString + str;
        return str;
      },
      itemStyle(condition, name, color) {
        if (condition) {
          return name + ':' + color;
        }
        else {
          return '';
        }
      },
      textValue(text) {
        if (this.isEmpty(text) == false) return text;
        else return "";
      },
      numberValue(number) {
        if (this.isEmpty(number) == false) return number;
        else return 0;
      },
      getShortened(text, maxLength) {
        let result = text + '';
        if (text.length > maxLength) {
          result = text.slice(0, maxLength) + '..';
        }
        return result;
      },
      isEmpty(text) {
        if ((text == undefined) || (typeof text == "undefined") || (text == null) || (text.length == 0)) {
          return true;
        }
        else {
          return false;
        }
      },
      isSameDate(date1, date2) {
        if (Number(date1) == Number(date2)) {
          return true;
        }
        else {
          return false;
        }
      },
      isSameDay(date1, date2) {
        let result = false;
        if (date1.getFullYear() == date2.getFullYear()) {
          if (date1.getMonth() == date2.getMonth()) {
            if (date1.getDate() == date2.getDate()) {
              result = true;
            }
          }
        }
        return result;
      },
      getPhoneText: function (text) {
        var result = "";
        if((text != null) && (text != undefined) && (text != 'undefined')) {
          var newText = (text + '').replace(/-/g, "");
          newText = (newText + '').replace(/ /g, "");
          if(newText.length > 0) {
            for (var i = 1; i <= newText.length; i++) {
              result = newText.charAt(newText.length - i) + result;
              if (i % 4 == 0 && i != newText.length) {
                result = "-" + result;
              }
            }
          }
        }
        return result;
      },
      onInput(text, object, regExp, maxLength, nextFocus, onMessage) {
        var result = text + '';
        if (regExp != null) {
          var matched = result.match(regExp);
          if (matched != null) {
            let matchedText = '';
            for (let i = 0; i < matched.length; i++) {
              matchedText += matched[i];
            }
            result = matchedText;
          }
          else {
            result = '';
          }
          if (result.length != text.length) {
            if (onMessage != null) {
              onMessage(text);
            }
          }
        }
        if (maxLength != null) {
          result = result.substring(0, maxLength);
        }
        object.lazyValue = result;
        if (nextFocus != null) {
          if (result.length == maxLength) {
            nextFocus.focus();
          }
        }
        return result;
      },
      getWeekday(date) {
        let weekday = date.getDay();
        if (weekday == 0) {
          return '일';
        }
        else if (weekday == 1) {
          return '월';
        }
        else if (weekday == 2) {
          return '화';
        }
        else if (weekday == 3) {
          return '수';
        }
        else if (weekday == 4) {
          return '목';
        }
        else if (weekday == 5) {
          return '금';
        }
        else if (weekday == 6) {
          return '토';
        }
        return 0;
      },
      randomValue(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      },
      createPhoneAuthCode() {
        return this.randomValue(100000, 999999) + '';
      },
      createHospitalCode() {
        return this.randomValue(10000, 99999) + '';
      },
      getSecondsDiff(startDate, endDate) {
        const msInSecond = 1000;
        return Math.round(
          Math.abs(endDate - startDate) / msInSecond
        );
      },
      getResidentNumber(text) {
        let numbers = text;
        if (text.length >= 8) {
          numbers = text.substring(0, 8);
          numbers += '******';
        }
        return numbers;
      },
      getPrivateResidentNumber(text) {
        let numbers = text;
        if (text.length >= 8) {
          numbers = text.substring(0, 8);
          numbers += '******';
        }
        return numbers;
      },
      getTelPhoneText: function (text) {
        let phoneNumber = text + '';
        let phone1 = '';
        let phone2 = '';
        let phone3 = '';
        if (phoneNumber.startsWith('02')) {
          // phone1: 앞 2자리, phone3: 뒤 4자리, phone2: 나머지
          phone1 = phoneNumber.slice(0, 2);
          phone2 = phoneNumber.slice(2, phoneNumber.length - 4);
          phone3 = phoneNumber.slice(phoneNumber.length - 4);
        } else {
          let areaCodes = ['031', '032', '033', '041', '042', '043', '044', '051', '052', '053', '054', '055', '061', '062', '063', '064'];
          let areaCode = phoneNumber.slice(0, 3);
          if (areaCodes.includes(areaCode)) {
            // phone1: 앞 3자리, phone3: 뒤 4자리, phone2: 나머지
            phone1 = phoneNumber.slice(0, 3);
            phone2 = phoneNumber.slice(3, phoneNumber.length - 4);
            phone3 = phoneNumber.slice(phoneNumber.length - 4);
          } else {
            // 4자리씩 구분
            let phoneNumbers = this.getPhoneText(phoneNumber).split('-');
            if (phoneNumbers.length > 2) {
              phone1 = phoneNumbers[0];
              phone2 = phoneNumbers[1];
              phone3 = phoneNumbers[2];
            }
          }
        }
        let result = phone1 + '-' + phone2 + '-' + phone3;
        if(result.length < 3) {
          result = '';
        }
        return result;
      },
      getBusinessNumber(businessNumber) {
        let result = businessNumber;
        if ((businessNumber != null) && (businessNumber != undefined)) {
          if (businessNumber.length == 10) {
            result = businessNumber.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
          }
        }
        return result;
      },
      getSmsLength: function (str) {
        var len = 0;
        if ((str != null) && (str != undefined)) {
          for (var i = 0; i < str.length; i++) {
            var c = str.charCodeAt(i);
            if (c > 128 || c == 0x22 || c == 0x27 || c == 0x2F || c == 0x5C || c == 0x7C) { // ", ', /, \, |
              len += 2;
            } else if (c == 0x0D) { // 0x0A 에서 1카운트 0x0D 는 무시
              len += 0;
            } else {
              len += 1;
            }
          }
        }
        return len;
      },
      convertYN2TF(value) {
        // convert Y or N to true or false;
        if (value === 'Y') {
          return true;
        } else {
          return false;
        }
      },
      deliveryStateToText(code) {
        const orderStateMap = {
          "OrderState.order": "주문등록",
          "OrderState.ready": "발송준비중",
          "OrderState.delivery": "배송중",
          "OrderState.deliveryPartial": "(부분)배송중",
          "OrderState.deliveryDone": "수령완료",
          "OrderState.cancel": "취소",
        };

        if (
            this.isEmpty(code) === false &&
            this.isEmpty(orderStateMap[code]) === false
        ) {
          return orderStateMap[code];
        } else {
          return "기타";
        }
      },
      getChartProgramList() {
        // DB (generalConfigs / chartProgramInfo)에서 가져오지 못했을 경우에 사용하는 데이터
        return ['의사랑', '비트플러스', '이지스', '닉스펜', '스마트닥터', '센스', '차트매니저', '닥터팔레트',
          '오름차트', '엣지앤넥스트', '트루닥', '이클릭', '베가스', '스마트CRM', '메디칼소프트', '덴트웹',
          '하나로', '한차트', 'OK차트', '닥터브레인', '중외정보기술', 'MD소프트', '모션E'];
      },
      getNoDataText() {
        return "데이터가 없습니다.";
      },
    }
  }
};

Vue.use(utils);
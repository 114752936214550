<template>
  <v-app>
    <div class="d-flex justify-start" style="width: 100%">
      <div :style="!$vuetify.breakpoint.xs ? 'min-width:210px;' : ''">
        <drawer
          v-if="testMenu"
          :drawer="drawer"
          :sidebarColor="sidebarColor"
          :sidebarTheme="sidebarTheme"
          :items="drawerItemsForTester"
          @goHome="$router.push({ name: '홈화면' })"
        >
        </drawer>
        <drawer
          v-else
          :drawer="drawer"
          :sidebarColor="sidebarColor"
          :sidebarTheme="sidebarTheme"
          :items="drawerItems"
          @goHome="$router.push({ name: '홈화면' })"
        >
        </drawer>
      </div>
      <div style="width: 100%">
        <app-bar
          background="bg-transparent"
          has-bg
          @drawer-toggle="toggleDrawer($event)"
          :toggle-active="drawer"
          :navbarFixed="navbarFixed"
        ></app-bar>
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>
  </v-app>
</template>

<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { FadeTransition } from "vue2-transitions";
import Drawer from "@/components/Drawer.vue";
import AppBar from "@/components/AppBar.vue";

export default {
  components: {
    FadeTransition,
    Drawer,
    AppBar,
  },
  data() {
    return {
      hospitalWorker: {},
      drawer: null,
      drawerItems: [
        {
          title: "홈",
          items: [
            {
              title: "홈화면",
              link: "/pages/dashboard/dashboard",
              icon: "fas fa-home",
              active: false,
            },
          ],
        },
        {
          title: "진료실",
          items: [
            {
              title: "구매관리",
              icon: "fas fa-shopping-cart",
              active: true,
              items: [
                // {
                //   title: "견적요청관리",
                //   link: "/pages/dashboard/quotation-requests",
                //   active: false,
                // },
                {
                  title: "견적관리",
                  link: "/pages/dashboard/quotations",
                  active: false,
                },
                {
                  title: "발주관리",
                  link: "/pages/dashboard/orders",
                  active: false,
                },
                {
                  title: "재고관리",
                  link: "/pages/dashboard/manage-stock",
                  active: false,
                },
                {
                  title: "상담관리",
                  link: "/pages/dashboard/quotation-customer-services",
                  active: false,
                },
                {
                  title: "결제내역",
                  active: false,
                  link: "/pages/dashboard/hospital-payments",
                },
                {
                  title: "결제카드관리",
                  active: false,
                  link: "/pages/dashboard/payment-card-info",
                },
                {
                  title: "구매설정",
                  active: false,
                  link: "/pages/dashboard/order-setting",
                },
              ],
            },
            {
              title: "예약관리",
              link: "/pages/dashboard/data-manager",
              icon: "fas fa-clipboard-list",
              active: false,
              items: [
                {
                  title: "신규예약등록",
                  link: "/pages/dashboard/data-manager",
                  active: false,
                },
                {
                  title: "예약완료조회",
                  link: "/pages/dashboard/data-manager",
                  active: false,
                },
                {
                  title: "예약요청조회",
                  link: "/pages/dashboard/reservation-data-manager",
                  active: false,
                },
              ],
            },
            {
              title: "정산내역",
              link: "/pages/dashboard/payment",
              icon: "fas fa-money-check-alt",
              active: false,
            },
            {
              title: "병원관리",
              icon: "fas fa-cog",
              active: false,
              items: [
                {
                  title: "기본정보수정",
                  link: "/pages/dashboard/default-settings",
                  active: false,
                },
                {
                  title: "의료진/직원 관리",
                  active: false,
                  link: "/pages/dashboard/members",
                },
                {
                  title: "공지/비급여관리",
                  link: "/pages/dashboard/hospital-pages",
                  active: false,
                },
                {
                  title: "진료환경설정",
                  active: false,
                  link: "/pages/dashboard/hospital-settings",
                },
                {
                  title: "푸시관리",
                  active: false,
                  link: "/pages/dashboard/notifications",
                },
                {
                  title: "결제내역",
                  active: false,
                  link: "/pages/dashboard/hospital-payments",
                },
              ],
            },
            {
              title: "고객관리",
              link: "/pages/dashboard/app-users",
              icon: "fas fa-user-friends",
              active: false,
            },
          ],
        },
        {
          title: "기타",
          items: [
            {
              title: "마이페이지",
              icon: "fas fa-user-cog",
              link: "/pages/dashboard/my-info",
              active: false,
            },
            {
              title: "고객센터",
              icon: "fas fa-question-circle",
              link: "/pages/dashboard/customer-services",
              active: false,
            },
          ],
        },
      ],
      drawerItemsForTester: [
        {
          title: "홈",
          items: [
            {
              title: "홈화면",
              link: "/pages/dashboard/dashboard",
              icon: "fas fa-home",
              active: false,
            },
          ],
        },

        {
          title: "진료실",
          items: [
            {
              title: "구매관리",
              icon: "fas fa-shopping-cart",
              active: true,
              items: [
                // {
                //   title: "견적요청관리",
                //   link: "/pages/dashboard/quotation-requests",
                //   active: false,
                // },
                {
                  title: "견적관리",
                  link: "/pages/dashboard/quotations",
                  active: false,
                },
                {
                  title: "발주관리",
                  link: "/pages/dashboard/orders",
                  active: false,
                },
                {
                  title: "재고관리",
                  link: "/pages/dashboard/manage-stock",
                  active: false,
                },
                {
                  title: "상담관리",
                  link: "/pages/dashboard/quotation-customer-services",
                  active: false,
                },
                {
                  title: "결제내역",
                  active: false,
                  link: "/pages/dashboard/hospital-payments",
                },
                {
                  title: "결제카드관리",
                  active: false,
                  link: "/pages/dashboard/payment-card-info",
                },
                {
                  title: "구매설정",
                  active: false,
                  link: "/pages/dashboard/order-setting",
                },
              ],
            },
            {
              title: "예약관리",
              link: "/pages/dashboard/data-manager",
              icon: "fas fa-clipboard-list",
              active: false,
              items: [
                {
                  title: "신규예약등록",
                  link: "/pages/dashboard/new-reservation",
                  active: false,
                },
                {
                  title: "예약완료조회",
                  link: "/pages/dashboard/data-manager",
                  active: false,
                },
                {
                  title: "예약요청조회",
                  link: "/pages/dashboard/reservation-data-manager",
                  active: false,
                },
              ],
            },
            {
              title: "정산내역",
              link: "/pages/dashboard/payment",
              icon: "fas fa-money-check-alt",
              active: false,
            },
            {
              title: "병원관리",
              icon: "fas fa-cog",
              active: false,
              items: [
                {
                  title: "기본정보수정",
                  link: "/pages/dashboard/default-settings",
                  active: false,
                },
                {
                  title: "의료진/직원 관리",
                  active: false,
                  link: "/pages/dashboard/members",
                },
                {
                  title: "공지/비급여관리",
                  link: "/pages/dashboard/hospital-pages",
                  active: false,
                },
                {
                  title: "진료환경설정",
                  active: false,
                  link: "/pages/dashboard/hospital-settings",
                },
                {
                  title: "푸시관리",
                  active: false,
                  link: "/pages/dashboard/notifications",
                },
                {
                  title: "결제내역",
                  active: false,
                  link: "/pages/dashboard/hospital-payments",
                },
              ],
            },
            {
              title: "고객관리",
              link: "/pages/dashboard/app-users",
              icon: "fas fa-user-friends",
              active: false,
            },
          ],
        },
        {
          title: "기타",
          items: [
            {
              title: "마이페이지",
              icon: "fas fa-user-cog",
              link: "/pages/dashboard/my-info",
              active: false,
            },
            {
              title: "고객센터",
              icon: "fas fa-question-circle",
              link: "/pages/dashboard/customer-services",
              active: false,
            },
          ],
        },
      ],
      sidebarColor: "success",
      sidebarTheme: "transparent",
      navbarFixed: false,
      testMenu: false,
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
    updateSidebarTheme(value) {
      this.sidebarTheme = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },
    toggleDrawer(event) {
      this.$nextTick(() => {
        this.drawer = false;
        this.$nextTick(() => {
          this.drawer = event;
        });
      });
    },
    async init() {
      let authUser = this.$auth.currentUser();
      let hospitalWorker = await this.$database.loadHospitalWorker(
        authUser.uid
      );
      let hospital = await this.$database.loadHospital(
        hospitalWorker.hospitalId
      );
      let appData = await this.$database.loadAdminConfig("appData");
      let testHospitals = appData.testHospitals;
      for (let i = 0; i < testHospitals.length; i++) {
        if (testHospitals[i].id == hospital.id) {
          this.testMenu = true;
          break;
        }
      }
    },
  },
  async mounted() {
    this.initScrollbar();
    this.init();
  },
};
</script>

<style lang="scss"></style>
